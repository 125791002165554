import Dissertation from './Dissertation.js';


function Projects() {
    return (
      <div>
        <Dissertation/>
      
      </div>

    );
  }

export default Projects;